import momentTimezone from "moment-timezone";
import l10n from "@/lib/localization/localization.js";

export default function(data) {
  const wData = data.data;
  let widget = {
    ...data.widgetPlacement,
    contentType: "NodeListCT",
    type: wData.content_type === "ding_event" ? "events" : "news",
    welcomeScreenType: "node-list",
    options: {
      items: []
    }
  };

  let imageSize = wData.type === "List" ? "/472x0" : "/700x0";
  let itemsLayout = "";
  let subType = "";

  if (wData.content_type === "ding_event" && wData.display_type === "movie_flow") {
    subType = "cinema";
  }

  if (wData.type === "Single") {
    widget.name = "node-list-carousel";
    imageSize = "/1920x0";
  } else if (wData.type === "KrsbibTwinItems") {
    widget.name = "node-list-carousel";
    widget.options.template = "krsbib-twin-items";
    imageSize = "/1920x0";
  } else if (wData.type === "SingleDarkBlue") {
    widget.name = "node-list-carousel";
    widget.withBackground = false;
    widget.options.template = "slagbib-booking";
    imageSize = "/1920x0";
  } else if (wData.type === "Carousel") {
    widget.name = "node-list-accordion";
  } else if (wData.type === "Tab") {
    widget.name = "node-list-tab";
    widget.options.design = window.ESCONFIG.defaultDesign === "light" ? "light" : undefined;
  } else if (wData.type === "List") {
    widget.name = "library-events-list";
    widget.withBackground = false;

    if (widget.height === 100) {
      itemsLayout = "ten-elements";
    } else {
      itemsLayout = "eight-elements";
    }
  } else if (wData.type === "SingleDetail") {
    widget.name = "node-list-carousel";
    widget.withBackground = false;
    widget.options.template = "single-detail";
    imageSize = "/1920x0";
  } else if (wData.type === "ColorImage") {
    widget.name = "node-list-carousel";
    widget.withBackground = false;
    widget.options.template = "color-image";
    imageSize = "/1920x0";
  } else if (wData.type === "GameList") {
    widget.name = "library-events-list";
    itemsLayout = "ten-elements";
    subType = "for-game";
  } else if (wData.type === "OnlyImage") {
    widget.name = "node-list-carousel";
    widget.withBackground = false;
    widget.options.template = "only-image";
    imageSize = "/1920x0";
  }

  widget.getter = async function nodelistGetter(context) {
    let vocabulary, terms, library, language;

    if (wData.filters) {
      vocabulary = [];
      terms = [];
      library = [];
      language = undefined;

      wData.filters.forEach(filter => {
        if (filter.field_id === "language") {
          language = filter.value_id;

          return;
        }

        if (filter.field_id === "library" && !library.includes(filter.value_id)) {
          library.push(filter.value_id);
          return;
        }

        if (!vocabulary.includes(filter.field_id)) {
          vocabulary.push(filter.field_id);
        }
        if (!terms.includes(filter.value_id)) {
          terms.push(filter.value_id);
        }
      });
    }

    const mobileSearchResponse = await context.$easyscreenRequest.lmsConnector.mobileSearch({
      type: wData.content_type === "ding_event" ? "events" : "news",
      amount: parseInt(wData.node_count, 10),
      nodes: wData.custom_articles ? wData.custom_articles.map(a => a.id) : undefined,
      vocabulary: vocabulary,
      terms: terms,
      library: library,
      language: language
    });

    if (!Array.isArray(mobileSearchResponse.items)) {
      mobileSearchResponse.items = [];
    }

    var _options = {
      items: [],
      title: wData.title,
      autoAnimation: true,
      animationDuration: parseInt(wData.timeout, 10),
      layout: [itemsLayout, subType].filter(Boolean)
    };

    if (wData.content_type === "ding_event") {
      /* contentType - are deprecated, use 'layout'. */
      _options.contentType = "events";
      _options.layout = _options.layout.concat(["events"]);
      _options.showDate = true;
      _options.items = mobileSearchResponse.items.map(item => {
        if (!("show_price" in wData) || wData.show_price === "1") {
          if (!item.price) {
            item.price = l10n("free");
          } else if (wData.price_suffix) {
            item.price = item.price + " " + wData.price_suffix;
          }
        } else {
          item.price = "";
        }

        if (context.$easyscreenSkin.isHorsholm) {
          // HACK: fix incorrect date handling in mobilesearch db for horsholm only
          const format = "YYYY-MM-DD HH:mm:ss";

          item.date = momentTimezone(item.date, format).tz("Europe/Copenhagen").format(format);
          item.dateTo = momentTimezone(item.dateTo, format).tz("Europe/Copenhagen").format(format);
        }

        item.showBackground = wData.show_background === "1";

        if (wData.show_background === "0") {
          item.bgImage = null;
        }

        if (wData.show_qr_code === "0") {
          item.qrCode = null;
        }

        ["image", "bgImage"].forEach(imageType => {
          if (item[imageType]) {
            item[imageType] = item[imageType].replace("/original", imageSize);
          }
        });

        return item;
      });
    } else {
      /* contentType - are deprecated, use 'layout'. */
      _options.contentType = "news";
      _options.layout = _options.layout.concat(["news"]);
      _options.items = mobileSearchResponse.items.map(item => {
        if (wData.show_background === "0") {
          item.bgImage = null;
        }

        if (wData.show_qr_code === "0") {
          item.qrCode = null;
        }

        ["image", "bgImage"].forEach(imageType => {
          if (item[imageType]) {
            item[imageType] = item[imageType].replace("/original", imageSize);
          }
        });

        return item;
      });
    }

    _options.layout = _options.layout.filter(Boolean);
    _options.items = _options.items.sort((a, b) => new Date(a.date) - new Date(b.date));

    return _options;
  };

  return widget;
}
