<template>
  <div :class="[
    'node-list-info',
    `node-list-info_${ layout }`,
    `node-list-info_scale-${ $easyscreenScale }`,
    `node-list-info_design-${ design }`,
    orientation
  ]">
    <div
      v-if="originalImage && ['welcome-screen', 'welcome-screen-light'].includes(layout)"
      class="node-list-info--image-wrapper"
    >
      <img

        ref="image"
        class="node-list-info--image"
        :src="originalImage"
        v-onready="_adjustTeaserHeight"
      >

      <template v-if="qrCode">
        <qr-code
          v-if="layout === 'welcome-screen-light'"
          class="node-list-info--qr-code"
          :qr-code="$easyscreenRequest.lmsConnector.wrapCover(qrCode)"
        />

        <div
          v-else
          class="node-list-info--qr-code"
          :style="{ backgroundImage: `url('${ $easyscreenRequest.lmsConnector.wrapCover(qrCode) }')` }"
        ></div>
      </template>
    </div>
    <div
      v-else-if="originalImage"
      class="node-list-info--css-image"
      :style="{ backgroundImage: `url('${ originalImage }')` }"
    ></div>

    <div class="node-list-info--type">{{ type }}</div>
    <h2 class="node-list-info--title">{{ title }}</h2>
    <div class="node-list-info--meta">
      <div>
        <div class="node-list-info--date">{{ formattedDate }}</div>
        <div class="node-list-info--location">{{ location }}</div>
      </div>
      <div
        v-if="type === 'event' && price"
        class="node-list-info--price"
      >{{ price }}</div>
    </div>

    <scrollable
      v-if="withScrollableContent"
      ref="scrollable"
      class="node-list-info--teaser ck-editor"
      :max-height="maxTeaserHeight"
    >
      <div v-html="anyTeaser"></div>
    </scrollable>

    <div v-else class="node-list-info--teaser ck-editor">
      <div v-html="anyTeaser"></div>
    </div>

    <slot></slot>
  </div>
</template>

<style src="../core/ck-editor.less" lang="less"></style>
<style src="./node-list-info.less" lang="less"></style>

<script>
  import moment from "moment";
  import getStyle from "@/lib/utils/get-style.js";
  import htmlElementHeight from "@/lib/utils/html-element-height.js";
  import l10n from "@/lib/localization/localization.js";
  import orientationMixin from "../core/mixins/orientation.js";
  import qrCode from "../qr-code/qr-code.vue";

  import Scrollable from "../core/scrollable/scrollable.vue";

  export default {
    name: "node-list-info",
    mixins: [orientationMixin],
    props: {
      layout: {
        type: String,
        default: "default",
        validator: (_skin) => ["default", "welcome-screen", "welcome-screen-light"].includes(_skin)
      },
      type: String,
      date: String,
      dateTo: String,
      location: String,
      price: [String, Number],
      image: String,
      title: String,
      esTeaser: String,
      teaser: String,
      text: String,
      lead: String,
      qrCode: String,
      single: {
        type: Boolean,
        default: false
      },
      /* The global reskin for materials list. */
      design: {
        type: String,
        default: "classic",
        validator: _design => ["classic", "light"].includes(_design)
      },
      withScrollableContent: {
        type: Boolean,
        default: true
      }
    },
    data() {
      return {
        maxTeaserHeight: this.layout === "default" ? "100%" : 830
      };
    },
    computed: {
      originalImage: {
        get() {
          return this.image && this.image.replace(/\/[0-9]*x[0-9]*\//, "/original/");
        }
      },
      formattedDate: {
        get() {
          const dateFrom = moment(this.date);
          const dateTo = moment(this.dateTo);
          let date = moment();

          if (date < dateFrom) {
            date = dateFrom;
          } else if (date > dateTo) {
            date = dateTo;
          }

          date.hour(dateFrom.hour());
          date.minute(dateFrom.minute());

          let formattedDate = date.format("D. MMMM");
          if (["welcome-screen", "welcome-screen-light"].includes(this.layout)) {
            formattedDate = date.format("DD.MM.YYYY");
          }

          if (this.$easyscreenSkin.isRclbib && (date.hour() !== 0 || date.minute() !== 0)) {
            return formattedDate + " " + date.format("HH.mm");
          }

          if (date.hour() !== 0 || date.minute() !== 0) {
            formattedDate += " " + l10n("Kl.") + " " + date.format("HH.mm");
          }

          return formattedDate;
        }
      },
      anyTeaser: {
        get() {
          return this.esTeaser || this.text || this.teaser || this.lead;
        }
      }
    },
    methods: {
      /**
       * Adjust the teaser height based on another elements in parent element to get scrollable working right.
       */
      _adjustTeaserHeight() {
        if (!this.$refs.scrollable)
          return;

        const totalHeight = getStyle(this.$el, "height");
        this.maxTeaserHeight = [].reduce.call(this.$el.children, (avaialbleHeight, child) => {
          if (child !== this.$refs.scrollable.$el) {
            avaialbleHeight -= htmlElementHeight(child);
          }

          return avaialbleHeight;
        }, totalHeight);
      }
    },
    components: {
      "scrollable": Scrollable,
      "qr-code": qrCode
    }
  };
</script>
