<template>
  <div :class="[
    'easyscreen-iframe',
    modificator ? `easyscreen-iframe_${ modificator }` : null
  ]">
    <loader
      v-if="withLoader && loaderShown"
      class="easyscreen-iframe--loader"
      type="in-place"
      :with-background="true"
      :design="design"
    />

    <div
      v-if="url"
      :class="[
        'easyscreen-iframe--wrapper',
        { 'easyscreen-iframe_with-overlay': overlay }
      ]"
    >
      <iframe
        class="easyscreen-iframe--frame"
        frameborder="0"
        sandbox="allow-same-origin allow-scripts allow-popups allow-forms"
        :src="clearedUrl"
        @load="loaderShown = false"
      ></iframe>
      <div
        v-if="overlay"
        class="easyscreen-iframe--overlay"
      ></div>
    </div>
    <h1 v-else class="easyscreen-iframe--alert">
      {{ $l10n("Url is empty") }}
    </h1>
  </div>
</template>

<style lang="less" src="./iframe.less"></style>

<script>
  import lodash from "lodash";
  import parseDuration from "parse-duration";
  import * as querystring from "querystring";
  import Loader from "../loader/loader.vue";

  export default {
    name: "easyscreen-iframe",
    watch: {
      url() {
        this.loaderShown = true;

        this._applyUrlOptions();
      }
    },
    computed: {
      clearedUrl() {
        let hash = this.url.split("#");
        let url = hash[0].split("?");
        hash = hash[1];

        let urlOptions = querystring.parse(url[1]);

        return [
          [url[0], querystring.stringify(lodash.omit(urlOptions, ["overrideStandby"]))].filter(Boolean).join("?"),
          hash
        ].filter(Boolean).join("#");
      }
    },
    props: {
      /* Url of iframe. */
      url: String,
      /* Flag for using overlay which prevents the users actions. */
      overlay: {
        type: Boolean,
        default: true
      },
      /* The modification of iframe view. */
      modificator: {
        type: String,
        default: "",
        validator: _modificator => ["", "content-border"].includes(_modificator)
      },
      withLoader: Boolean,
      design: {
        type: String,
        default: "classic",
        validator: _design => ["classic", "light"].includes(_design)
      }
    },
    data() {
      return {
        loaderShown: true
      };
    },
    methods: {
      _getEasyscreenUrlOptions(url) {
        let urlOptions = querystring.parse(url.split("#")[0].split("?")[1]);
        let easyscreenOptions = lodash.pick(urlOptions, ["overrideStandby"]);
        if (easyscreenOptions.overrideStandby)
          easyscreenOptions.overrideStandby = parseDuration(easyscreenOptions.overrideStandby);

        return easyscreenOptions;
      },
      _applyUrlOptions() {
        let overrides = this._getEasyscreenUrlOptions(this.url);
        if (overrides.overrideStandby) {
          this._originalPendingTime = this.$easyscreenScreenActivity.pendingTime;
          this._originalOverrideFlag = this.$easyscreenScreenActivity.dontOverwrite;

          this.$easyscreenScreenActivity.useIframeDropFocusFallback = false;
          this.$easyscreenScreenActivity.changePendingTime(overrides.overrideStandby, { dontOverwrite: true, force: true });
          this.$easyscreenScreenActivity.action();
        }
      },
      _restoreDefaultOptions() {
        if (this._originalPendingTime != null) {
          this.$easyscreenScreenActivity.useIframeDropFocusFallback = true;
          this.$easyscreenScreenActivity.changePendingTime(this._originalPendingTime, { dontOverwrite: this._originalOverrideFlag, force: true });
          this._originalPendingTime = null;
        }
      }
    },
    mounted() {
      this._applyUrlOptions();
    },
    beforeDestroy() {
      this._restoreDefaultOptions();
    },
    components: {
      Loader
    }
  };
</script>
