import * as querystring from "querystring";
import lodash from "lodash";

export default function(data) {
  const urlOptions = querystring.parse(window.location.href.split("#")[1]);
  const wData = data.data;

  const promotedMaterialsData = lodash.get(wData, "themes.items[0].settings") || { items: [] };
  if (promotedMaterialsData.theme_color_enabled !== "1")
    delete promotedMaterialsData.theme_color_value;

  let widget =  {
    ...data.widgetPlacement,
    contentType: "SearchScreen",
    name: "easyscreen-search--visual-shelf",
    options: {
      title: promotedMaterialsData.theme,
      queryPrefix: wData.prefix,
      withInspirationScanner: wData.scanner === 1,
      promotedMaterials: promotedMaterialsData.items,
      promotedMaterialsBackgroundColor: promotedMaterialsData.theme_color_value,
      inspirationScannerWithCoversOnly: wData.scanner_coversonly === 1,
      inspirationScannerAvailableOnly: wData.scanner_availableonly === 1,
      inspirationScannerDepartmentId: wData.scanner_department,
      inspirationScannerBranchId: wData.scanner_branch,
      inspirationScannerTitle: wData.scanner_text,
      design: window.ESCONFIG.defaultDesign,
      /* Disable the bottom navigation for the light design (ES-2402). */
      disableNavigation: "disableNavigation" in urlOptions,
      wayfinderData: {
        viewLabel: lodash.get(data, "screenOptions.map_view_label"),
        viewId: lodash.get(data, "screenOptions.map_view_id"),
        here: urlOptions.here
      }
    }
  };

  return widget;
}
