import * as querystring from "querystring";
import { set, get, castArray } from "lodash";
import head from "./head.js";
import textAndImage from "./text-and-image.js";
import libraryEvents from "./library-events.js";
import fullscreen from "./fullscreen.js";
import video from "./video.js";
import youtube from "./youtube.js";
import nodeList from "./node-list.js";
import presentationSetCollection from "./presentation-set-collection.js";
import digitalShelf from "./digital-shelf.js";
import materialPromotion from "./material-promotion.js";
import openingHours from "./opening-hours.js";
import standaloneSearch from "./standalone-search.js";
import webContent from "./web-content.js";
import inspirationScanner from "./inspiration-scanner.js";
import qrShelf from "./qr-shelf.js";
import game from "./game.js";
import eventsPromition from "./events-promotion.js";
import visualShelvesV2 from "./visual-shelves-v2.js";

const widgetsMap = {
  TextPlusImageCT: textAndImage,
  ImageCT: textAndImage,
  GoogleEventsCT: libraryEvents,
  LibraryEventsCT: libraryEvents,
  FullScreenCT: fullscreen,
  VideoCT: video,
  YouTubeCT: youtube,
  NodeListCT: nodeList,
  PresentationSetCollectionCT: presentationSetCollection,
  DigitalShelfCT: digitalShelf,
  M2Shelf: digitalShelf,
  MaterialPromotionCT: materialPromotion,
  OpeningHoursCT: openingHours,
  SearchScreen: standaloneSearch,
  WebContent: webContent,
  QrShelf: qrShelf,
  InspirationScanner: inspirationScanner,
  Game: game,
  EventsPromitionCT: eventsPromition,
  VisualShelvesCT: standaloneSearch,
  VisualShelvesV2CT: visualShelvesV2
};

function slideFilter(slide) {
  return get(slide, "widgets.length", 0) !== 0;
}

export async function slideConverter(data, options, slides) {
  let slide = {
    duration: get(data, "slideData.duration", 60) * 1000,
    widgets: []
  };

  const urlOptions = querystring.parse(window.location.href.split("#")[1]);
  if ("duration" in urlOptions && options.withDurationOverride !== false) {
    slide.duration = parseInt(urlOptions.duration, 10) * 1000;
  }

  if (get(data, "slideData.slideName") === "[events-promotion]") {
    set(data, "widgets[0].widgetName", "EventsPromitionCT");
  }

  if (Array.isArray(data.widgets) && data.widgets.length !== 0) {
    slide.data = data.slideData;

    await Promise.all(data.widgets.map(async (widget) => {
      const converter = widgetsMap[widget.widgetName];
      if (!converter) {
        console.warn("Missing coverter for widget:", widget);
        return;
      }

      let convertedWidget = await converter({
        ...widget,
        widgetPlacement: {
          height: (widget.options[window.canvasOrientation] || widget.options.vertical).location.height || 0,
          left: (widget.options[window.canvasOrientation] || widget.options.vertical).location.left || 0,
          top: (widget.options[window.canvasOrientation] || widget.options.vertical).location.top || 0,
          width: (widget.options[window.canvasOrientation] || widget.options.vertical).location.width || 0
        },
        screenOptions: options.screenOptions
      }, slide.data);

      convertedWidget.options = convertedWidget.options || {};
      convertedWidget.options.slideSettings = slide.data;

      slide.widgets = slide.widgets.concat(castArray(convertedWidget));
    }));

    if (options.slideSwitcher && slide.widgets.length !== 0) {
      const w = slide.widgets[0];

      slide.widgets[0] = {
        left: w.left,
        top: w.top,
        width: w.width,
        height: w.height,
        name: "video-host",
        options: {
          widgetName: w.name,
          options: w.options,
          slides: slides.filter(slideFilter)
        }
      };

      if (slide.widgets[1]) {
        slide.widgets[1].padding = false;
      }
    }

    slide.widgets.forEach(widget => {
      widget.options = widget.options || {};
    });

    if (slide.widgets.length === 1) {
      let slideOptions = slide.widgets[0];
      if (slideOptions.height === 100 && slideOptions.width === 100) {
        slideOptions.padding = false;
      }
    }

    if (data.widgets[0].widgetName !== "FullScreenCT") {
      slide.widgets = head(data.slideData.overrideTitle || data.slideData.slideName, slides, slide).concat(slide.widgets);
    }
  }

  return slide;
}

export async function slidesCoverter(data, options) {
  return (await Promise.all((data || []).map(datum => slideConverter(datum, options, data)))).filter(slideFilter);
}

export default slidesCoverter;
